import React from 'react'
import './about.scss';
import moment from 'moment/moment';


function About({data}) {
    // console.log("data from about:",data)
  return (
    <div className='about'>
      <h1>About</h1>
      <hr/>
      <div className="user-details">
        <div className="item">
            <h4>NAME</h4>
            <p>{data.userFirstName +" "+ data.userLastName}</p>
        </div>
        <div className="item">
            <h4>EMAIL</h4>
            <p>{data.userEmail}</p>
        </div>
      </div>
      <div className="user-details">
        <div className="item">
            <h4>D.O.B</h4>
            <p>{moment(data.userBirthDate).format('DD MMM YYYY')} </p>
        </div>
        <div className="item">
            <h4>LOCATION</h4>
            <p>{data.userLocation}</p>
        </div>
      </div>
      <div className="user-details">
        <div className="item">
            <h4>GENDER</h4>
            <p>{data.userGender}</p>
        </div>
        <div className="item">
            <h4>HOBBIES</h4>
            <p>{data.userHobbies}</p>
        </div>
      </div>
      {(data.orgId != 0) && 
      <div className="user-details">
      <div className="item">
          <h4>ORGNISATION</h4>
          <p>{data.userOrgname}</p>
      </div>
      <div className="item">
          <h4>MOBILE NO.</h4>
          {/* <p>{data.userMobileNumber}</p> */}
      </div>
    </div>
    }
    </div>
  )
}

export default About
