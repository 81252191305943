import React from 'react'
import './aboutPage.scss';
import moment from "moment";

function AboutPage({data}) {
    // console.log("data from about group:",data)
  return (
    <div className='about'>
      <h1>About</h1>
      <hr/>
      <div className="user-details">
        <div className="item">
            <h4>NAME</h4>
            <p>{data.pageName}</p>
        </div>
        <div className="item">
            <h4>DESCRIPTION</h4>
            <p>{data.pageDescription}</p>
        </div>
      </div>
      <div className="user-details">
        <div className="item">
            <h4>ADMIN</h4>
            <p>{data.adminUserFirstName} {data.adminUserLastName}</p>
        </div>
        <div className="item">
            <h4>CREATED AT</h4>
            <p>{moment(data.groupCreatedAt).format('MMMM Do YYYY') }</p>
        </div>
      </div>
      {data.linkUrl && <div className="user-details">
        <div className="item">
            <h4>Do Visit</h4>
            <a href={data.linkUrl} target="_blank">{data.linkUrlName ? data.linkUrlName : "click_Here"}</a>
        </div>        
      </div>}
    </div>
  )
}

export default AboutPage
