  
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./groups.scss";
import { makeRequest } from "../../axios";
import { AuthContext } from "../../context/authContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { DarkModeContext } from "../../context/darkModeContext";
import { message, Modal } from "antd";
import image from "../../assets/login_image.jpg"; // Ensure you have a fallback image for groups

function Groups() {
  const [hasGroups, setHasGroups] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null); // Track the group to delete
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { currentUser } = useContext(AuthContext);
  const { darkMode } = useContext(DarkModeContext);

  const getGroupsOfUser = async (userId) => {
    setLoading(true);
    try {
      const response = await makeRequest.get(`/ssakti/users/group/getGroups/${userId}`);
      if (response) {
        setHasGroups(true);
        setGroups(response.data.groupDetails);
      } else {
        setHasGroups(false);
      }
    } catch (err) {
      console.error(err);
      setHasGroups(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGroupsOfUser(currentUser.id);
  }, [currentUser.id]);

  const handleDelGrp = async () => {
    if (selectedGroup) {
      try {
        const response = await makeRequest.delete(`/ssakti/users/group/deleteGroup/${selectedGroup.adminId}/${selectedGroup.groupId}`);
        if (response) {
          message.success("Group deleted successfully");
          getGroupsOfUser(currentUser.id);
        } else {
          message.error("Failed to delete group, try later");
        }
      } catch (err) {
        console.error(err);
        message.error("Failed to delete group");
      } finally {
        setIsModalVisible(false);
        setSelectedGroup(null);
      }
    }
  };

  return (
    <div className="selectGroups">
      {isLoading ? (
        <div className="loading">
          <p>Loading....<div className="loader"></div></p>
        </div>
      ) : hasGroups ? (
        <div>
          <div className="head">
            <h1>Your groups</h1>
            <button>
              <Link to="/createGroups">Create group</Link>
            </button>
          </div>
          <hr />
          <div className="usergroups">
            {groups.map((grp) => (
              <div className="group-container" key={grp.groupId}>
                <Link to={`/groups/${grp.groupUUID}`} className="user">
                  <div className="left">
                    <img
                      src={grp.groupCoverProfileImagePath || image}
                      width="150px"
                      height="100px"
                      alt={grp.groupName}
                    />
                  </div>
                  <div className="right">
                    <h4>{grp.groupName}</h4>
                    <h5>{grp.groupDescription}</h5>
                    <h6>{grp.adminUserFirstName}</h6>
                  </div>
                </Link>
                {currentUser.id === grp.adminId && (
                  <div className="deleteGrp">
                    <button onClick={() => { setSelectedGroup(grp); setIsModalVisible(true); }}>
                      <DeleteIcon
                        className="size-4"
                        sx={{ color: !darkMode ? "#000" : "#fff" }}
                      />
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="noGroups">
          <h1>Oops, no groups found.</h1>
          <button>
            <Link to="/createGroups">Create one for you!</Link>
          </button>
        </div>
      )}

      <Modal
        title="Delete Group"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <button
            style={{
              backgroundColor: "#e4002b",
              color: "white",
              border: "none",
              borderRadius: "5px",
              width: "4rem",
              height: "2rem",
              fontWeight: "600",
              fontSize: "0.9rem",
            }}
            key="delete"
            type="primary"
            onClick={handleDelGrp}
          >
            Delete
          </button>,
        ]}
      >
        <p>Are you sure you want to delete this group?</p>
      </Modal>
    </div>
  );
}

export default Groups;

