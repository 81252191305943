
import React, { useContext, useEffect, useState } from 'react'
import './createPage.scss'
import { AuthContext } from '../../context/authContext';
import { makeRequest } from '../../axios';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk'
import Image from "../../assets/img.png";
import heic2any from "heic2any";

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

function CreatePages() {

  const { currentUser } = useContext(AuthContext);

  const [pageName, setPageName] = useState('');
  const [pageDesc, setPageDesc] = useState('');
  const [aboutLink,setAboutLink] = useState('');
  const [linkName,setLinkName] = useState('');
  // const [pageCat, setPageCat] = useState('');
  const [isCreatingPage,setIsCreatingPage] = useState(false)

  const navigate = useNavigate();

  const createPage = async () => {
    if (!pageName || !pageDesc) {                                           
      notification.error({
        message: 'Error',
        description: 'Please fill in all the fields before creating the page.',
      });
      return;
    }
  
    
    try {
      setIsCreatingPage(true);
  
      const response = await makeRequest.post(
        `/ssakti/users/pages/createPage?adminUserId=${currentUser.id}`,
        {
          "pageName": pageName,
          "pageDescription": pageDesc,
          "pageCoverProfileImagePath": null,
          "linkUrl": aboutLink,
          "linkUrlName":linkName 
        }
      );
  
      notification.success({
        message: 'Group created successfully',
        description: "People can follow your pages.",
      });
      navigate('/userPage');
    } catch (error) {
  
      notification.error({
        message: error.response.data.message || 'something went wrong',
        description: 'There was an error creating the group. Please try again.',
      });
    } finally {
      setIsCreatingPage(false);
    }
  };

  return (
    <div className='createGroups'>
      <h1>Create a new Page</h1>
      <div className='formGroups'>
        <input className='inp' name='pageName' value={pageName} onChange={(e)=>setPageName(e.target.value)} type="text" placeholder='Page Name' />
        <input className='inp' name='pageDesc' value={pageDesc} onChange={(e)=>setPageDesc(e.target.value)} type="text" placeholder='What is this page about (bio)' />
        <input className='inp' name='aboutLink' value={aboutLink} onChange={(e)=>setAboutLink(e.target.value)} type="url" placeholder='Add a Link in page About' />
        <input className='inp' name='linkName' value={linkName} onChange={(e)=>setLinkName(e.target.value)} type="text" placeholder='Give a name for Link' />

        {/* /*category section*/ }
          {/* <select className="inp" value={pageCat} name="pageCat" onChange={(e)=>setPageCat(e.target.value)} id="category-select">
              <option value="">Page Category</option>
              <option value="food-drink">Food & Drink</option>
              <option value="travel-adventure">Travel & Adventure</option>
              <option value="lifestyle">Lifestyle</option>
              <option value="technology">Technology</option>
              <option value="entertainment">Entertainment</option>
              <option value="health-wellness">Health & Wellness</option>
              <option value="education">Education</option>
              <option value="finance">Finance</option>
              <option value="home-garden">Home & Garden</option>
              <option value="sports">Sports</option>
            </select> */}
        <button onClick={createPage}>{isCreatingPage ? "Creating  Page.." : "Create Page"}</button>
      </div>
    </div>
  )
}

export default CreatePages
