import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,

} from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import LeftBar from "./components/leftBar/LeftBar";
import RightBar from "./components/rightBar/RightBar";
import Home from "./pages/home/Home";
import Profile from "./pages/profile/Profile";
import FriendsLising from "./pages/friendsListing/FriendsListng";
import Messages from "./pages/messages/Messages";
import Groups from "./pages/groups/Groups";
import Reels from "./pages/reels/Reels";
import UserPage from "./pages/userPage/UserPage";
import "./style.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import { AuthContext } from "./context/authContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import BottomNavBar from "./components/bottomNavBar/BottomNavBar";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import CommingSoon from "./pages/notFound/CommingSoon";
// admin imports
import AdminDashboard from "./pages/adminDashboard/AdminDashboard";
import AdminLeftBar from "./components/admin/adminLeftBar/AdminLeftBar";
import AdminNavbar from "./components/admin/adminNavBar/AdminNavbar";
import PostManager from "./pages/postManager/Postmanager";
import UserManager from "./pages/userManager/UserManager";
import Saved from "./pages/saved/Saved";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import CreatePage from "./pages/createPage/CreatePage";
import Advetisements from "./components/admin/advetisements/Advetisements";
import UserPageSelect from "./pages/userPageSelect/UserPageSelect";
import CreateGroups from "./pages/createGroups/CreateGroups";
import SingleGroup from "./pages/singleGroup/SingleGroup";
import ExplorePages from "./pages/explorePages/ExplorePages";
import Ads from "./pages/ads/Ads";
import EventsUser from "./pages/events/EventsUser";
import UserEvents from "./pages/UserEvents/UserEvents";
import SingleEvent from "./pages/singleEvent/SingleEvent";
import CreateEvent from "./pages/createEvent/CreateEvent";
import ChatSakhi from "./pages/ai/ChatSakhi";

import { I18nextProvider } from 'react-i18next';
import i18n from './context/i18n'; 
import GroupManager from "./pages/groupManager/GroupManager";
import PageManager from "./pages/pageManager/PageManger";
import GroupChatWindow from "./pages/singleGroup/GroupChatWindow";

function App() {
  const { currentUser } = useContext(AuthContext);

  const { darkMode } = useContext(DarkModeContext);

  const queryClient = new QueryClient();

  const Layout = () => {
    return (
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
        <div className={`theme-${darkMode ? "dark" : "light"}`}>
          <Navbar />
          <div style={{ display: "flex" }}>
            <LeftBar />
            <div style={{ flex: "5 2" }}>
              <Outlet />
            </div>
            <RightBar />
            
          </div>
          <BottomNavBar />
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
        </I18nextProvider>
      </QueryClientProvider>
    );
  };

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/login" />;
    }

    if (currentUser && currentUser.isAdmin) {
      return <Navigate to="/admin/dashboard" />;
    }

    if (currentUser && currentUser.isSuperAdmin) {
      return <Navigate to="/superAdmin/dashboard" />;
    }

    return children;
  };

  const AdminLayout = ({ children }) => {
    return (
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18n}>
        <div className={`theme-${darkMode ? "dark" : "light"}`}>
          <AdminNavbar />
          <div style={{ display: "flex" }}>
            <AdminLeftBar />
            <div style={{ flex: 6 }}>
              <Outlet />
            </div>
            {/* <RightBar /> */}
          </div>
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
        </I18nextProvider>
      </QueryClientProvider>
    );
  };
  
  const SuperAdminLayout = ({ children }) => {
    return (
      <QueryClientProvider client={queryClient}>
        <div className={`theme-${darkMode ? "dark" : "light"}`}>
          <AdminNavbar />
          <div style={{ display: "flex" }}>
            <AdminLeftBar />
            <div style={{ flex: 6 }}>
              <Outlet />
            </div>
            {/* <RightBar /> */}
          </div>
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    );
  };

  const AdminProtectedRoute = ({ children }) => {
    const { currentUser } = useContext(AuthContext);

    if (!currentUser || !currentUser.isAdmin) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  const SuperAdminProtectedRoute = ({ children }) => {
    const { currentUser } = useContext(AuthContext);

    if (!currentUser || !currentUser.isSuperAdmin) {
      return <Navigate to="/login" />;
    }

    return children;
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/profile/:id",
          element: <Profile />,
        },
        {
          path: "/friends",
          element: <FriendsLising />,
        },
        {
          path: "/messages",
          element: <Messages />,
        },
        {
          path: "/groups",
          element: <Groups />,
        },
        {
          path: "/groups/:id",
          element: <SingleGroup />,
        },
        {
          path: "/groups/:id/groupChat",
          element: <GroupChatWindow />,
        },
        {
          path:"/createGroups",
          element: <CreateGroups />,
        },
        {
          path: "/reels",
          element: <Reels />,
        },
        {
          path: "/userPage",
          element: <UserPageSelect />,
        },
        {
          path: "/createPage",
          element: <CreatePage />,
        },
        {
          path: "/courses",
          element: <CommingSoon />,
        },
        {
          path: "/marketplace",
          element: <CommingSoon />,
        },
        {
          path: "/collaborations",
          element: <CommingSoon />,
        },
        {
          path: "/videos",
          element: <CommingSoon />,
        },
        {
          path: "/live",
          element: <CommingSoon />,
        },
        {
          path: "/ads",
          element: <Ads/>,
        },
        {
          path: "/saved",
          element: <Saved />,
        },
        {
          path: "/userPage",
          element: <UserPageSelect />,
        },
        {
          path: "/userPage/:id",
          element: <UserPage />,
        },
        {
          path: "/explorepages",
          element: <ExplorePages/>,
        },
        {
          path: "/events",
          element: <EventsUser/>,
        },
        {
          path: "/yourEvents",
          element: <UserEvents/>,
        },
        {
          path: "/events/:id",
          element: <SingleEvent/>,
        },
        {
          path: "/createEvents",
          element: <CreateEvent/>,
        }  
      ],
    },
    {
        path: "/chat/sakhi",
        element: <ChatSakhi/>,
    
    },
    {
        path: "/chat/sakhi",
        element: <ChatSakhi/>,
    
    },
    {
      path: "/login",
      element: <Login />,
    },
    
    {
      path: "/register",
      element: <Register />,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/admin",
      element: (
        <AdminProtectedRoute>
          <AdminLayout />
        </AdminProtectedRoute>
      ),
      children: [
        {
          path: "dashboard",
          element: <AdminDashboard />,
        },
        {
          path: "usermanager",
          element: <UserManager />,
        },
        {
          path: "postsmanager",
          element: <PostManager />,
        },
        {
          path: "groupmanager",
          element: <GroupManager />,
        },
        {
          path: "pagemanager",
          element: <PageManager />,
        },
        {
          path: "advetisements",
          element: <Advetisements />,
        },
      ],
    },
    {
      path:"/superAdmin",
      element: (
        <SuperAdminProtectedRoute>
          <SuperAdminLayout />
        </SuperAdminProtectedRoute>
      ),
      children: [
        {}
      ]
    }
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
