import React, { useState, useContext, useEffect } from "react";
import Post from "../../components/post/Post";
import AboutGroup from "./AboutGroup";
import FriendsListing from ".././friendsListing/FriendsListng"
import './tabs.scss';
import { AuthContext } from "../../context/authContext";
import { makeRequest } from "../../axios";
import { notification } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import AWS from "aws-sdk";
import heic2any from "heic2any";
import SendIcon from "@mui/icons-material/Send";
import Image from "../../assets/img.png";
import { Navigate, useNavigate } from "react-router-dom";
import imageCompression from 'browser-image-compression';

const awsRegion = process.env.REACT_APP_AWS_REGION;
const awsSecretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const awsAccessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;

const ProfileTabsGroup = ({ groupMembers, userId, selectedTab, data, post }) => {

  // console.log(groupMembers)
  return (
    <div className="profileTabs">

      <div className="tabContent">
        {selectedTab === "Posts" && <GroupPost  post={post} data={data} />}
        {selectedTab === "AboutGroup" && <AboutGroup data={data} />}
        {selectedTab === "Friends" && <MembersGroup groupMembers={groupMembers} data={data} />}
        {/* {selectedTab === "Friends" && <FriendsListing userId={userId} />} */}
      </div>
    </div>
  );
};

export default ProfileTabsGroup;


export const GroupPost = ({ post, data}) => {
  // console.log('this is post :',post)

  const { currentUser } = useContext(AuthContext)

  const [file, setFile] = useState(null);
  const [desc, setDesc] = useState("");
  const S3_BUCKET = "streesakti";
  const [inputNameError, setInputNameError] = useState("");

  AWS.config.update({
    accessKeyId: awsAccessKeyId,
    secretAccessKey: awsSecretAccessKey,
    region: awsRegion,
  });

  const s3 = new AWS.S3();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newPost) => {
      return makeRequest.post(
        `/ssakti/users/group/addPost/${currentUser.id}/${data.groupId}`,
        newPost
      );
    },
    {
      onSuccess: () => {

        queryClient.invalidateQueries(["groupDetails"])
        notification.success({
          message: "Post uploaded successfully",
          description: "Your post has been shared with everyone.",
        });
      },
    }
  );

  const handleInput = (e) => {
    setDesc(e.target.value);
    if (e.target.value.length > 0) {
      setInputNameError("");
    }
  };  

  const handleClick=async()=>{
    try {
      if (!desc && !file) {
            notification.error({
              message: 'No Image or No Description',
              description: 'You have to add an image or a description.',
            });
            return;
      }
      else{
        if(file){
            let postGroup;
          if (file.type.startsWith('image')) {
            const imageOptions = {
              maxSizeMB: 0.10 , // 150KB
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            const compressedBlob = await imageCompression(file, imageOptions);

          // Convert the compressed Blob back to a File
          postGroup = new File([compressedBlob], file.name, {
            type: compressedBlob.type,
            lastModified: file.lastModified,
          });
            
          } else if (file.type.includes('video')) {
            const maxVideoSizeMB = 15;
            const videoSizeMB = file.size / 1024 / 1024;
            if (videoSizeMB > maxVideoSizeMB) {
              notification.error({
                message: 'Video Too Large',
                description: `The video size is ${videoSizeMB.toFixed(2)} MB, which exceeds the 15MB limit.`,
              });
              return;
            }
            postGroup = file;
          }

          const formData = new FormData();
          formData.append("postName", desc);
          formData.append("postImage", postGroup);
          formData.append("postType", postGroup.type);
          mutation.mutate(formData)
          setFile(null);
          setDesc('');
        }else{
          const formData = new FormData();
          formData.append("postName", desc);          
          mutation.mutate(formData)
          setFile(null);
          setDesc('');
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
    {
       currentUser.id === data.adminId &&
     <div className="shareGrp" style={{width:"80%",marginRight:"auto",marginLeft:'auto'}}>
              <div className="container">
                <div className="top">
                  <div className="left">
                    <img src={currentUser.profilePic} alt="" />
                    <textarea
                      rows={"3"}
                      type="text"
                      placeholder={`What's on your mind ${currentUser.name}?`}
                      onChange={handleInput}
                      value={desc}
                    />
                  </div>
                  <div className="right">
                    {file && file.type.startsWith("image/") && (
                      <img
                        className="file"
                        alt=""
                        src={URL.createObjectURL(file)}
                      />
                    )}
                    {file && file.type.startsWith("video/") && (
                      <video className="file" controls>
                        <source
                          src={URL.createObjectURL(file)}
                          type={file.type}
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {!file && <p>No file selected</p>}{" "}
                    {/* Message when no file is selected */}
                  </div>
                </div>
                {inputNameError && (
                  <span className="inputError">{inputNameError}</span>
                )}
                <hr />
                <div className="bottom">
                  <div className="left">
                    <input
                      type="file"
                      id="file"
                      accept="image/*,video/*"
                      style={{ display: "none" }}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                    <label htmlFor="file">
                      <div className="item">
                        <img src={Image} alt="" />
                        <span>Add Image/Video</span>
                      </div>
                    </label>
                  </div>
                  <div className="rightGrp">
                    <button onClick={handleClick}>
                      Share
                    </button>
                  </div>
                </div>
              </div>
            </div>
    }
    {
      post.length == 0 ?
      <p>No posts found.</p>
      :
      post.map((post)=>{
        return(
          <Post post={post} key={post.postId}/>
        )
      })
    }
    </>
  )
}

export const MembersGroup = ({ groupMembers, data}) => {
const queryClient = useQueryClient();

  const { currentUser } = useContext(AuthContext)

  console.log(currentUser)
  const navigate = useNavigate()
  const removeUserFromGroupByAdmin = async (id) => {
    try {
      const response = await makeRequest.delete(`/ssakti/users/group/exitGroup/${data.groupId}/${data.adminId}/${id}`)
      console.log(response)
      if (response) {
        notification.success({
          message: 'User removed successfully',
        });
        // navigate(`/groups`)
       queryClient.invalidateQueries(["groupDetails"])
      }
      else {
        notification.error({
          message: 'Error',
          description: "Failed to remove the user.",
        });
      }
      // console.log("user")
    }
    catch (error) {
      console.log(error)
      notification.error({
        message: 'Error',
        description: "Failed to remove the user.",
      });
    }
  }

  const handleRedirect = (id) => {
    navigate(`/profile/${id}`)
  }

  return (
    <div className="members">
      <h1>Members</h1>
      {groupMembers.length === 0 ? (
        <div className="noMem">No members in this group</div>
      ) : (
        groupMembers.map((member) => (
          <div className="member" key={member.userId}>
            <div className="right">
              <img src={member.userProfileImagePath} alt="profilePic" />
              <h3 onClick={() => handleRedirect(member.userUUID)}>
                {member.userFirstName} {member.userlastName}
              </h3>
            </div>
            <div className="left">
              {currentUser.id === data.adminId && (
                <button onClick={() => removeUserFromGroupByAdmin(member.userId)} className="remove">
                  Remove from group
                </button>
              )}
            </div>
          </div>
        ))
      )}
    </div>
  )
}