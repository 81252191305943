import "./pageManager.scss";
import { makeRequest } from "../../axios";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { notification } from 'antd';
import moment from 'moment';
import { useState } from "react";
import { Pagination, Select } from 'antd';
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { downloadCSV } from "../../helpers/csvExport/downloadCSV.js";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

const PageManager = () => {
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(12);
  const [searchInput, setSearchInput] = useState("");
  const [triggerFetch, setTriggerFetch] = useState(true)
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const navigate = useNavigate();
  const { Option } = Select;


  const { data: districts } = useQuery(['districts'], () => {
    return makeRequest.get('/ssakti/users/district/getAllDistricts').then((res) => res.data);
  }, 
  {
    refetchOnWindowFocus:false,
  }
);

  const fetchUsers = async (page = 1, size = 6) => {
    const endpoint = `ssakti/admin/user/getAllUserWithPagination`;
    try {
      const response = await makeRequest.get(endpoint, {
        params: { firstName: searchInput, districtId: selectedDistrict, page: page - 1, size },
      });

      if (response.status === 204) {
        return    {
          hasNextPage: false,
          totalPages: 0,
          pageSize: 0,
          nextPageNo: 0,
          currentPage: 0,
          totalElements: 0,
          userDetails: []
        };
      }
      setTriggerFetch(false)
      return response.data;
    
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  };

  const { data, isLoading, isError,isFetching } = useQuery(
    ['getUserList', currentPage, pageSize],
    () => fetchUsers(currentPage, pageSize),
    {
      keepPreviousData: true,
      refetchOnWindowFocus:false,
      enabled: triggerFetch,
      
    },
  );

  const handlePostClick = (userId) => {
    navigate('/admin/postsmanager', { state: { userId } });
  }

  const onPageChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
    setTriggerFetch(true);
  };


  const mutation = useMutation((id) => {
    return makeRequest.delete("ssakti/admin/user/deleteUser/" + id);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('getUserList');
      notification.success({
        message: 'Page Deleted Successfully',
        description: 'All the posts and comments have been deleted',
      });
    },
    onError: () => {
      notification.error({
        message: 'Deletion Failed',
        description: 'Failed to delete page. Please try again.',
      });
    },
  });

  const deleteUser = (userId) => {
    if (window.confirm('Are you sure you want to delete this page?')) {
      mutation.mutate(userId);
    }
  };

  if (isError) {
    return <p>Error fetching pages</p>;
  }

  if (isLoading || isFetching ) {
    return <p>Loading...</p>;
  }

  if(!data){
    return <p>No Pages found</p>;
  }


  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchInput(value);
  };

  const handleDistrictChange = (value) => {
    setSelectedDistrict(value); // Update selected district
  };

  const handleSubmit = () => {
    setTriggerFetch(true)
  }


  const handleCsvDownload = () => {
    downloadCSV(['userFirstName', 'userLastName', 'userEmail'], data?.userDetails);
  }

  return (       
    <div className="adminUserManager">
      <h2>Page Manager</h2>

      <div className="top-bar-container">
        <div className="menu-left">
          <div className="search">
            <SearchOutlinedIcon aria-label="Search" />
            <input
              type="text"
              placeholder="Search"
              value={searchInput}
              onChange={handleSearchInputChange}
            />
          </div>

          <div>
            <Select
              placeholder="Select District"
              value={selectedDistrict}
              onChange={handleDistrictChange}
              style={{ width: 200, marginLeft: '1rem' }}
            >
              <Option value={null}>All Districts</Option>
              {districts?.getAllDistrictData?.map(district => (
                <Option key={district.districtId} value={district.districtId}>
                  {district.districtName}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>

      <div className="page-list">        
        <table className="page-table">
          <thead>
            <tr>
              <th>Page Profile</th>
              <th>Page Name</th>
              <th>Page Admin</th>
              <th>Email</th>
              <th>Total Posts</th>
              <th>Total Followers</th>              
            </tr>
          </thead>
          <tbody>
            {data?.userDetails.length < 1 ? (
              <tr>
                <td colSpan="7" className="no-data">
                  No groups found
                </td>
              </tr>
            ) : (
              data?.userDetails.map((page) => (
                <tr key={page.id}>
                  <td>
                    <div className="page-profile">
                      <img
                        src={page.userProfileImagePath}
                        alt={`${page.userFirstName} ${page.userLastName}`}
                      />
                    </div>
                  </td>
                  <td>{page.userFirstName}</td>
                  <td>{`${page.userFirstName} ${page.userLastName}`}</td>
                  <td>{page.userEmail}</td>
                  <td>
                    <Tooltip title="See all posts of page" aria-label="see posts" onClick={() => handlePostClick(page.userId)}>
                      {page.totalPostCount}
                    </Tooltip>
                  </td>
                  <td>{page.totalPostCount}</td>                  
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <div>
        <Pagination
          showSizeChanger
          onChange={onPageChange}
          current={currentPage}
          pageSize={pageSize}
          total={data?.totalElements || 0}
          pageSizeOptions={[4, 8, 12, 16, 32, 64, 10000]}
        />
      </div>
    </div> 
  );
};

export default PageManager;
