import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/authContext';
import { makeRequest } from '../../axios';
import "./singleEvent.scss"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import Groups2Icon from '@mui/icons-material/Groups2';
import { Modal } from "antd";
import { notification } from "antd";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";

function SingleEvent() {

    const params = useParams();
    const eventId = params.id
    const {currentUser} = useContext(AuthContext)
    const [eventAbout,setEventAbout] = useState(null)
    const [eventHost,setEventHost] = useState(null)
    console.log(params)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [seletedModalComponent, setSeletedModalComponent] = useState(null);
    const navigate = useNavigate();

    const getEventDetails = async()=>{
        try {
            const response = await makeRequest.get(`/ssakti/users/event/getDetails/${currentUser.id}/${eventId}`)
            console.log(response)
            setEventAbout(response.data.about[0])
            setEventHost(response.data.discussion[0])
        } catch (error) {
            console.error(error)
            setEventAbout({})
            setEventHost({})
        }
    }

    useEffect(()=>{
        getEventDetails()
    },[])

    const handleModal = (component) => {
      setIsModalVisible(true);
      console.log(component)
      setSeletedModalComponent(component)
    }
  
    let content;
    switch (seletedModalComponent){
        
        case "viewParticipant":
        content = <ViewParticipant eventAbout={eventAbout} params={params} setIsModalVisible={setIsModalVisible}/>
        break;
      case "participate":
        content = <Participate  getEventDetails={getEventDetails} eventAbout={eventAbout} params={params} currentUser={currentUser} setIsModalVisible={setIsModalVisible}/>
        break;
      case "nonParticipate":
        content = <NonParticipate  getEventDetails={getEventDetails} eventAbout={eventAbout} params={params} currentUser={currentUser} setIsModalVisible={setIsModalVisible}/>
        break;  
    }

  return (
    <div className='singleEventPage'>
      <img className='eveCover' src={eventAbout?.postImageUrl}/>
      <div className='head-Container'>
        <div className='date'>
          <div className='headDate'></div>
          <div className='btDate'>{eventAbout?.startDate.split("-").slice(2,5)}</div>
        </div>
        {(eventAbout?.isParticipant == false && eventHost?.userId != currentUser.id)  && 
        <div className='actionBtn'>
              <button className='button-clr' onClick={()=>handleModal("participate")}>
                Participate                
              </button>
        </div>}
        {(eventAbout?.isParticipant == true && eventHost?.userId != currentUser.id) && 
        <div className='actionBtn'>
              <button className='button-clr' onClick={()=>handleModal("nonParticipate")}>
                Non-Participate               
              </button>
        </div>}
      </div>
      <div className='eventDetails'>
        <div style={{display:"flex",width:"100%",justifyContent:'space-between',alignItems:"center"}}>
        <div>
        <h2>{eventAbout?.eventName} </h2>
        <h5>{eventAbout?.eventDescription} </h5>
        </div>
        <div className='btn-container'>
        {(eventHost?.userId != currentUser.id)&& 
          <div className='catName'>
              <h6>{eventAbout?.categoryName}</h6>
              {/* {(eventHost?.userId == currentUser.id)&& 
              <button>
                Edit
              </button>} */}
          </div>}
          {(eventHost?.userId == currentUser.id)&& 
          <div className='catName'>
            
              <button className='button-clr' onClick={()=>handleModal("viewParticipant")}>
                View Participent
              </button>
          </div>}
        </div>
        </div>
        <div className='detDesc'>
            <AccessTimeIcon /> {eventAbout?.startTime} - {eventAbout?.endTime}
        </div>
        <div className='detDesc'>
            <CalendarMonthIcon /> {eventAbout?.startDate}   -   {eventAbout?.endDate}
        </div>
        <div className='detDesc'>
            <FmdGoodIcon /> {eventAbout?.eventAddress} {eventAbout?.districtName}
        </div>
        {eventAbout?.virtualEventLink  &&<div className='detDesc'>
            <Groups2Icon/>  <a href={eventAbout?.virtualEventLink} target="_blank">Join Here Virtually</a>
        </div>}
        <hr style={{marginTop:"50px",marginBottom:"50px"}}/>
        <h2>Hosted by :</h2>
        <div style={{display:'flex',gap:"20px",alignItems:"center",margin:"10px"}}>
            <img src={eventHost?.userProfileImageUrl} width={"50px"} height={"50px"} style={{borderRadius:"50%",objectFit:"cover"}}/>
            <Link to={`/profile/${eventHost?.userUUID}`} className='userLink'>{eventHost?.userName}</Link>
        </div>
        <h5>This event was hosted {eventHost?.postUploadedAt}</h5>
      </div>
      <Modal
            visible={isModalVisible}
            // onOk={handleLogout}
            onCancel={() => setIsModalVisible(false)} 
            footer={null}
          >
         {content}
          </Modal>
    </div>
  )
}

export default SingleEvent


export const ViewParticipant=({eventAbout,params,setIsModalVisible})=>{
  
    const queryClient = useQueryClient();
  
    const { currentUser } = useContext(AuthContext)
  
    console.log(params)
  
    // console.log(currentUser)
    const navigate = useNavigate()    
  
    const removeParticipant = async(id)=>{    
      try{
        const response = await makeRequest.delete(`/ssakti/users/participant/exitEvent/${id}/${eventAbout.eventId}`)
        console.log(response)
        if(response){        
          notification.success({
            message: 'Participent remove successfully',
          });         
          queryClient.invalidateQueries(['cparticpants', currentUser.id]);  
        }
        else{
          notification.error({
            message: 'Error',
            description: "Failed to remove Participent.",
          });
        }        
      }
      catch(error){
        console.log(error)
        notification.error({
          message: 'Error',
          description: "Failed to remove Participent.",
        });
      }    
    }
  
  
    const fetchParticipants = async ({ pageParam = 0 }) => {
      const endpoint = `/ssakti/users/participant/getParticipants/${params.id}`;
      try {
        const response = await makeRequest.get(endpoint, {
          params: { page: pageParam, size: 6 }, // Paginate results
        });
    
        // If the response status is 204, handle "no content" scenario
        if (response.status === 204) {
          console.log('No content received from server');
          return {
            data: [],
            hasNextPage: false,
            nextPageNo: undefined,
          };
        }
    
        // Otherwise, process the posts and pagination metadata
        return {
          data: response.data.details,  // Posts list
          hasNextPage: response.data.hasNextPage,  // More posts available?
          nextPageNo: response.data.nextPageNo,  // The next page number
        };
      } catch (error) {
        console.error('Fetch error:', error);
        throw error;
      }
    };
  
    const {
      data,
      fetchNextPage,
      hasNextPage,
      isLoading,
      isFetching,
      isError,
    } = useInfiniteQuery(
      ['cparticpants', currentUser.id], // Query key    
      fetchParticipants, // Fetch function
      removeParticipant,    
      
      {
        getNextPageParam: (lastPage) => {
          // Return nextPageNo if there are more pages, otherwise return undefined
          return lastPage.hasNextPage ? lastPage.nextPageNo : undefined;
        },
        refetchOnWindowFocus: false, // Optional: prevent refetching on window focus
      }
    );
  
    const participants = data?.pages?.flatMap((page) => page.data) || [];  
  
    useEffect(() => {
      const handleScroll = () => {
        if (
          window.innerHeight + document.documentElement.scrollTop
          < document.documentElement.offsetHeight - 100 || // Trigger a bit before the bottom
          isFetching
        ) return;
  
        if (hasNextPage) {
          fetchNextPage();
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, [fetchNextPage, hasNextPage, isFetching]);
  
  
    const handleRedirect = (id) => {
      navigate(`/profile/${id}`)
    }
  
    return (
      <div className="members">
        <h1>Participants</h1>
  
        {isLoading && <h1 style={{color:"black"}}>Loading participants...</h1>}
  
        {isError && <h1 style={{color:"black"}}>Failed to load participants.</h1>}
  
        {!isLoading && !isError && participants.length === 0 && (
          <h1 style={{color:"black"}}>No participants in this Event</h1>
        )}
  
        {!isLoading && !isError && participants.length > 0 && (
          participants.map((participant) => (
            <div className="member" key={participant.userId}>
              <div className="right">
                <img
                  className='profile-img'
                  src={participant.userProfileImagePath || "/default-avatar.png"}
                  alt="profilePic"
                />
                <h3 onClick={() => handleRedirect(participant.uuid)}>
                  {participant.userFirstName} {participant.userLastName}
                </h3>
              </div>
              <div className="removeParticipant">
                      <button className="actionBtn" onClick={() =>removeParticipant(participant.userId)}>
                        Remove
                      </button>
                    </div>
            </div>
          ))
        )}
  
        {isFetching &&  <CircularProgress />}
  
       
      </div>
    ) 
}

export const Participate=({eventAbout,getEventDetails,params,setIsModalVisible})=>{  

  console.log(eventAbout)
  const participateInComp=async()=>{
    try{
      const response = await makeRequest.post(`/ssakti/users/participant/add/${eventAbout.eventId}`)
      console.log(response)
      if(response){
        notification.success({
          message: 'Competition participated successfully',
        });
        getEventDetails();
        setIsModalVisible(false);
      }
      else{
        notification.error({
          message: 'Error',
          description: "Failed to participate in Competition.  in else",
        });
      }
    }
    catch(error){
      console.log(error)
      notification.error({
        message: 'Error',
        description: "Failed to participate in Competition. in catch",
      });
    }
  }

  return(
    <div>
      <h1 style={{margin:"20px"}}>Participate</h1>
      <p style={{fontSize:"0.8rem",marginBottom:"10px"}}>Do you want to participate in this Event?</p>
      <button style={{backgroundColor:'#87A2FF',color:"white",border:"none",padding:"10px",borderRadius:"5px",fontWeight:"700"}} onClick={participateInComp}>Participate</button>
    </div>
  )
}

export const NonParticipate=({eventAbout,getEventDetails,currentUser,params,setIsModalVisible})=>{

  const nonParticipateInEvent=async()=>{
    try{
      const response = await makeRequest.delete(`/ssakti/users/participant/exitEvent/${currentUser.id}/${eventAbout.eventId}`)
      console.log(response)
      if(response){
        notification.success({
          message: 'Event Non-participated successfully',
        });
        getEventDetails();
        setIsModalVisible(false);
      }
      else{
        notification.error({
          message: 'Error',
          description: "Failed to Non-Participate in Event.",
        });
      }
    }
    catch(error){
      console.log(error)
      notification.error({
        message: 'Error',
        description: "Failed to Non-Participate in Event.",
      });
    }
  }

  return(
    <div>
      <h1 style={{margin:"20px"}}>Exit</h1>
      <p style={{fontSize:"0.8rem",marginBottom:"10px"}}>Do you want to exit from this Event?</p>
      <button style={{backgroundColor:'#87A2FF',color:"white",border:"none",padding:"10px",borderRadius:"5px",fontWeight:"700"}} onClick={nonParticipateInEvent}>Exit</button>
    </div>
  )
}